import CelsiusBase from '@/module/CelsiusBase'

export default class Celsius extends CelsiusBase {
  density () {
    return 0.00002 *
      Math.pow(this.attributes.celsius.operatingTemperature, 3) -
      0.0012 *
      Math.pow(this.attributes.celsius.operatingTemperature, 2) -
      0.4703 *
      this.attributes.celsius.operatingTemperature +
      1249.8
  }

  specificHeatCapacity () {
    return (-0.0000002407425 *
      Math.pow((this.attributes.celsius.operatingTemperature + 273.15), 3) +
      0.0002043931 *
      Math.pow((this.attributes.celsius.operatingTemperature + 273.15), 2) -
      0.05421699 *
      (this.attributes.celsius.operatingTemperature + 273.15) +
      7.210778)
  }

  thermalConductivity () {
    return 0.0009 * this.attributes.celsius.operatingTemperature + 0.4226
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        -2.5 +
        5.73 *
        Math.log(this.attributes.celsius.operatingTemperature + 273.15) -
        0.9 *
        Math.pow((Math.log(this.attributes.celsius.operatingTemperature + 273.15)), 2)
      )
    )
  }
}
