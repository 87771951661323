<template>
  <div>
    <h4 class="mt-6">Thermal expansion</h4>
    <div class="columns mt-2 mb-6 is-multiline">
      <div class="column">
        <div class="field">
          <label class="label">Temper Product</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select data-test="fieldTemper" v-model.number="form.temper">
                <option value="-10">Temper -10</option>
                <option value="-15">Temper -15</option>
                <option value="-20">Temper -20</option>
                <option value="-30">Temper -30</option>
                <option value="-40">Temper -40</option>
                <option value="-55">Temper -55</option>
                <option value="-60">Temper -60</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Units</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select data-test="fieldMode" v-model="form.mode">
                <option value="celsius">Celsius</option>
                <option value="fahrenheit">Fahrenheit</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Volume</label>
          <div class="control has-icons-right">
            <input
              class="input is-expanded"
              type="number"
              data-test="fieldVolume"
              v-model.number="form.volume"
            />
            <span
              class="icon is-small is-right is-larger"
              v-html="unitToggle('L', 'US Gallon')"
            ></span>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Fluidtemp min</label>
          <div class="control has-icons-right">
            <input
              class="input is-expanded"
              type="number"
              data-test="fieldMinimumFluidTemperature"
              v-model.number="form.minimumFluidTemperature"
            />
            <span
              class="icon is-small is-right"
              v-html="unitToggle('&deg;C', '&deg;F')"
            >
            </span>
          </div>
          <p
            class="help is-danger"
            data-test="errorOperatingTemperature"
            v-if="errors.minimumFluidTemperature"
            v-html="errors.minimumFluidTemperature"
          ></p>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Fluidtemp max</label>
          <div class="control has-icons-right">
            <input
              class="input is-expanded"
              type="number"
              data-test="fieldMaximumFluidTemperature"
              v-model.number="form.maximumFluidTemperature"
            />
            <span
              class="icon is-small is-right"
              v-html="unitToggle('&deg;C', '&deg;F')"
            ></span>
          </div>
          <p
            class="help is-danger"
            data-test="errorOperatingTemperature"
            v-if="errors.maximumFluidTemperature"
            v-html="errors.maximumFluidTemperature"
          ></p>
        </div>
      </div>
    </div>
    <div id="thermal-expansion-table">
      <h5>Values</h5>
      <table class="table is-narrow is-bordered is-fullwidth">
        <thead>
          <tr>
            <th>Temper Product</th>
            <th>Units</th>
            <th>Volume</th>
            <th>Fluidtemp min</th>
            <th>Fluidtemp max</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Temper {{ form.temper }}</td>
            <td class="is-capitalized">{{ form.mode }}</td>
            <td>
              {{ form.volume }}
              <span v-html="unitToggle('L', 'US Gallon')"></span>
            </td>
            <td>
              {{ form.minimumFluidTemperature }}
              <span v-html="unitToggle('&deg;C', '&deg;F')"> </span>
            </td>
            <td>
              {{ form.maximumFluidTemperature }}
              <span v-html="unitToggle('&deg;C', '&deg;F')"></span>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Results</h5>
      <div class="table-container">
        <table
          class="table is-narrow physical-properties thermal-expansions calculator is-bordered is-fullwidth is-striped"
        >
          <thead>
          <tr>
            <th>Density min-temp</th>
            <th>Density max-temp</th>
            <th>Result</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              {{ calculator.expansion.minimumDensity | decimal(2) }} kg/m<sup
            >3</sup
            >
            </td>
            <td>
              {{ calculator.expansion.maximumDensity | decimal(2) }} kg/m<sup
            >3</sup
            >
            </td>
            <td>
              {{ calculator.expansion.percentage | decimal(2) }} % |
              {{ calculator.expansion[this.form.mode].volume | decimal(2) }}
              <span v-html="unitToggle('L', 'US Gallon')"></span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <footer class="buttons mt-6">
      <button class="button" @click="print">
        Print data
      </button>
    </footer>
  </div>
</template>

<script>
import Calculator from '@/module/Calculator'

export default {
  name: 'ThermalExpansion',
  data () {
    return {
      form: {
        mode: 'celsius',
        temper: -10,
        volume: 0,
        minimumFluidTemperature: 0,
        maximumFluidTemperature: 0
      }
    }
  },
  computed: {
    calculator () {
      return Calculator.get(this.form)
    },
    isCelsius () {
      return this.form.mode === 'celsius'
    },
    isFahrenheit () {
      return this.form.mode === 'fahrenheit'
    },
    errors () {
      const minError = this.testError(this.form.minimumFluidTemperature)
      const maxError = this.testError(this.form.maximumFluidTemperature, minError ? null : this.form.minimumFluidTemperature)

      return {
        minimumFluidTemperature: minError,
        maximumFluidTemperature: maxError
      }
    }
  },
  filters: {
    decimal (value, count) {
      return value.toFixed(count)
    }
  },
  methods: {
    testError (value, overrideMin = null) {
      const products = [-10, -15, -20, -30, -40, -55, -60]

      const match = products.find(product => product === this.form.temper)

      if (this.isCelsius) {
        const max = 40

        const min = overrideMin !== null ? overrideMin : match

        if (
          this.form.temper === match &&
          (value < min ||
            value > max)
        ) {
          return `Value should be between ${min}&#176;C and ${max}&#176;C`
        }
      } else if (this.isFahrenheit) {
        const fahrenheit = overrideMin !== null ? overrideMin : Math.round((match * 9) / 5 + 32)

        const max = 104

        if (
          this.form.temper === match &&
          (value < fahrenheit ||
            value > max)
        ) {
          return `Value should be between ${fahrenheit}&#176;F and ${max}&#176;F`
        }
      }

      return ''
    },
    unitToggle (celsius, fahrenheit) {
      return this.isCelsius ? celsius : fahrenheit
    },
    print () {
      this.$htmlToPaper('thermal-expansion-table')
    }
  }
}
</script>
