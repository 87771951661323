export default {
  install (Vue) {
    Vue.prototype.$cookieNotification = new Vue({
      data () {
        return {
          applicationName: 'Temper'
        }
      },
      created () {
        $(document).on('click', '.faqs .faqs-accordion h4', function() {
            if(!$(this).parent().parent().hasClass('active')) {
                $('.faqs .faqs-accordion').removeClass('active');
                $('.faqs .faqs-accordion .faq-content').slideUp();
                $(this).parent().parent().addClass('active');
                $(this).parent().parent().children('.faq-content').slideDown();
            }
        });
      }
    })
  }
}
