import ExpansionBase from '@/module/ExpansionBase'

export default class Expansion extends ExpansionBase {
  minimumDensity () {
    return -0.0013 * Math.pow(this.attributes.celsius.minimumFluidTemperature, 2) - 0.361 * this.attributes.celsius.minimumFluidTemperature + 1214.9
  }

  maximumDensity () {
    return -0.0013 * Math.pow(this.attributes.celsius.maximumFluidTemperature, 2) - 0.361 * this.attributes.celsius.maximumFluidTemperature + 1214.9
  }
}
