import ExpansionBase from '@/module/ExpansionBase'

export default class Expansion extends ExpansionBase {
  minimumDensity () {
    return 0.00002 * Math.pow(this.attributes.celsius.minimumFluidTemperature, 3) - 0.0012 * Math.pow(this.attributes.celsius.minimumFluidTemperature, 2) - 0.475 * this.attributes.celsius.minimumFluidTemperature + 1269.8
  }

  maximumDensity () {
    return 0.00002 * Math.pow(this.attributes.celsius.maximumFluidTemperature, 3) - 0.0012 * Math.pow(this.attributes.celsius.maximumFluidTemperature, 2) - 0.475 * this.attributes.celsius.maximumFluidTemperature + 1269.8
  }
}
