<template>
  <div>
    <h4 class="mt-6">Thermal properties</h4>
    <div class="columns mt-6 mb-6 is-multiline">
      <div class="column">
        <div class="field">
          <label class="label">Temper Product</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select data-test="fieldTemper" v-model.number="form.temper">
                <option value="-10">Temper -10</option>
                <option value="-15">Temper -15</option>
                <option value="-20">Temper -20</option>
                <option value="-30">Temper -30</option>
                <option value="-40">Temper -40</option>
                <option value="-55">Temper -55</option>
                <option value="-60">Temper -60</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Units</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select data-test="fieldMode" v-model="form.mode">
                <option value="celsius">Celsius</option>
                <option value="fahrenheit">Fahrenheit</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Operating Temperature</label>
          <div class="control has-icons-right">
            <input
              class="input is-expanded"
              type="number"
              data-test="fieldOperatingTemperature"
              v-model.number="form.operatingTemperature"
            />
            <span
              class="icon is-small is-right"
              v-html="unitToggle('&deg;C', '&deg;F')"
            >
            </span>
          </div>
          <p
            class="help is-danger"
            data-test="errorOperatingTemperature"
            v-if="errors.operatingTemperature"
            v-html="errors.operatingTemperature"
          ></p>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Length</label>
          <div class="control has-icons-right">
            <input
              class="input is-expanded"
              type="number"
              data-test="fieldLength"
              v-model.number="form.length"
            />
            <span
              class="icon is-small is-right"
              v-html="unitToggle('m', 'ft')"
            ></span>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Tube Diameter</label>
          <div class="control has-icons-right">
            <input
              class="input is-expanded"
              type="number"
              data-test="fieldTubeDiameter"
              v-model.number="form.tubeDiameter"
            />
            <span
              class="icon is-small is-right"
              v-html="unitToggle('m', 'inch')"
            ></span>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Fluid Flow Velocity</label>
          <div class="control has-icons-right">
            <input
              class="input is-expanded"
              type="number"
              data-test="fieldFluidFlowVelocity"
              v-model.number="form.fluidFlowVelocity"
            />
            <span
              class="icon is-small is-right"
              v-html="unitToggle('m/s', 'ft/s')"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div id="thermal-properties-table">
      <h5>Values</h5>
      <table class="table is-narrow is-bordered is-fullwidth mb-6">
        <thead>
          <tr>
            <th>Temper Product</th>
            <th>Units</th>
            <th>Operating Temperature</th>
            <th>Length</th>
            <th>Tube Diameter</th>
            <th>Fluid Flow Velocity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Temper {{ form.temper }}</td>
            <td class="is-capitalized">{{ form.mode }}</td>
            <td>
              {{ form.operatingTemperature }}
              <span v-html="unitToggle('&deg;C', '&deg;F')"> </span>
            </td>
            <td>
              {{ form.length }}
              <span v-html="unitToggle('m', 'ft')"></span>
            </td>
            <td>
              {{ form.tubeDiameter }}
              <span v-html="unitToggle('m', 'inch')"></span>
            </td>
            <td>
              {{ form.fluidFlowVelocity }}
              <span v-html="unitToggle('m/s', 'ft/s')"></span>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Results</h5>
      <div class="table-container">
        <table
          class="table is-narrow physical-properties calculator is-bordered is-fullwidth is-striped"
        >
          <thead>
            <tr>
              <th></th>
              <th>Temper {{ form.temper }}</th>
              <th v-if="!disableMpg">MPG</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Density</td>
              <td v-if="isCelsius">
                <strong>{{ calculator.celsius.density | decimal(2) }}</strong>
                kg/m<sup>3</sup>
              </td>
              <td v-if="isCelsius && !disableMpg">
                <strong>{{
                  calculator.mpgCelsius.density | decimal(2)
                }}</strong>
                kg/m<sup>3</sup>
              </td>
              <td v-if="isFahrenheit">
                <strong>{{
                  calculator.fahrenheit.density | decimal(2)
                }}</strong>
                lb/ft<sup>3</sup>
              </td>
              <td v-if="isFahrenheit && !disableMpg">
                <strong>{{
                  calculator.mpgFahrenheit.density | decimal(2)
                }}</strong>
                lb/ft<sup>3</sup>
              </td>
            </tr>
            <tr>
              <td>Specific Heat Capacity</td>
              <td v-if="isCelsius">
                <strong>{{
                  calculator.celsius.specificHeatCapacity | decimal(2)
                }}</strong>
                kJ/kg.K
              </td>
              <td v-if="isCelsius && !disableMpg">
                <strong>{{
                  calculator.mpgCelsius.specificHeatCapacity | decimal(2)
                }}</strong>
                kJ/kg.K
              </td>
              <td v-if="isFahrenheit">
                <strong>{{
                  calculator.fahrenheit.specificHeatCapacity | decimal(2)
                }}</strong>
                BTU/lb F
              </td>
              <td v-if="isFahrenheit && !disableMpg">
                <strong>{{
                  calculator.mpgFahrenheit.specificHeatCapacity | decimal(2)
                }}</strong>
                BTU/lb F
              </td>
            </tr>
            <tr>
              <td>Thermal Conductivity</td>
              <td v-if="isCelsius">
                <strong>{{
                  calculator.celsius.thermalConductivity | decimal(2)
                }}</strong>
                W/m.K
              </td>
              <td v-if="isCelsius && !disableMpg">
                <strong>{{
                  calculator.mpgCelsius.thermalConductivity | decimal(2)
                }}</strong>
                W/m.K
              </td>
              <td v-if="isFahrenheit">
                <strong>{{
                  calculator.fahrenheit.thermalConductivity | decimal(2)
                }}</strong>
                BTU/hr ft F
              </td>
              <td v-if="isFahrenheit && !disableMpg">
                <strong>{{
                  calculator.mpgFahrenheit.thermalConductivity | decimal(2)
                }}</strong>
                BTU/hr ft F
              </td>
            </tr>
            <tr>
              <td>Dynamic Viscosity</td>
              <td v-if="isCelsius">
                <strong>{{
                  calculator.celsius.dynamicViscosity | decimal(2)
                }}</strong>
                mPa.s, cP
              </td>
              <td v-if="isCelsius && !disableMpg">
                <strong>{{
                  calculator.mpgCelsius.dynamicViscosity | decimal(2)
                }}</strong>
                mPa.s, cP
              </td>
              <td v-if="isFahrenheit">
                <strong>{{
                  calculator.fahrenheit.dynamicViscosity | decimal(2)
                }}</strong>
                cP
              </td>
              <td v-if="isFahrenheit && !disableMpg">
                <strong>{{
                  calculator.mpgFahrenheit.dynamicViscosity | decimal(2)
                }}</strong>
                cP
              </td>
            </tr>
            <tr>
              <td>Kinematic Viscosity</td>
              <td v-if="isCelsius">
                <strong>{{
                  calculator.celsius.kinematicViscosity | decimal(2)
                }}</strong>
                mm<sup>2</sup>/s, cSt
              </td>
              <td v-if="isCelsius && !disableMpg">
                <strong>{{
                  calculator.mpgCelsius.kinematicViscosity | decimal(2)
                }}</strong>
                mm<sup>2</sup>/s, cSt
              </td>
              <td v-if="isFahrenheit">
                <strong>{{
                  calculator.fahrenheit.kinematicViscosity | decimal(2)
                }}</strong>
                cSt
              </td>
              <td v-if="isFahrenheit && !disableMpg">
                <strong>{{
                  calculator.mpgFahrenheit.kinematicViscosity | decimal(2)
                }}</strong>
                cSt
              </td>
            </tr>
            <tr>
              <td>Reynolds number</td>
              <td v-if="isCelsius">
                <strong>{{
                  calculator.celsius.reynoldsNumber | decimal(2)
                }}</strong>
                {{
                  calculator.celsius.reynoldsNumber > 2300
                    ? 'Turbulent Flow, Re > 2300'
                    : 'Laminar Flow, Re < 2300'
                }}
              </td>
              <td v-if="isCelsius && !disableMpg">
                <strong>{{
                  calculator.mpgCelsius.reynoldsNumber | decimal(2)
                }}</strong>
                {{
                  calculator.mpgCelsius.reynoldsNumber > 2300
                    ? 'Turbulent Flow, Re > 2300'
                    : 'Laminar Flow, Re < 2300'
                }}
              </td>
              <td v-if="isFahrenheit">
                <strong>{{
                  calculator.fahrenheit.reynoldsNumber | decimal(2)
                }}</strong>
                {{
                  calculator.fahrenheit.reynoldsNumber > 2300
                    ? 'Turbulent Flow, Re > 2300'
                    : 'Laminar Flow, Re < 2300'
                }}
              </td>
              <td v-if="isFahrenheit && !disableMpg">
                <strong>{{
                  calculator.mpgFahrenheit.reynoldsNumber | decimal(2)
                }}</strong>
                {{
                  calculator.mpgFahrenheit.reynoldsNumber > 2300
                    ? 'Turbulent Flow, Re > 2300'
                    : 'Laminar Flow, Re < 2300'
                }}
              </td>
            </tr>
            <tr>
              <td>Pressure drop</td>
              <td v-if="isCelsius">
                <strong>{{
                  calculator.celsius.pressureDrop | decimal(2)
                }}</strong>
                kPa
              </td>
              <td v-if="isCelsius && !disableMpg">
                <strong>{{
                  calculator.mpgCelsius.pressureDrop | decimal(2)
                }}</strong>
                kPa
              </td>
              <td v-if="isFahrenheit">
                <strong>{{
                  calculator.fahrenheit.pressureDrop | decimal(2)
                }}</strong>
                psi
              </td>
              <td v-if="isFahrenheit && !disableMpg">
                <strong>{{
                  calculator.mpgFahrenheit.pressureDrop | decimal(2)
                }}</strong>
                psi
              </td>
            </tr>
            <tr>
              <td>Heat transfer coefficient</td>
              <td v-if="isCelsius">
                <strong>{{
                  calculator.celsius.heatTransferCoefficient | decimal(2)
                }}</strong>
                W/m<sup>2</sup> .&deg;C
              </td>
              <td v-if="isCelsius && !disableMpg">
                <strong>{{
                  calculator.mpgCelsius.heatTransferCoefficient | decimal(2)
                }}</strong>
                W/m<sup>2</sup> .&deg;C
              </td>
              <td v-if="isFahrenheit">
                <strong>{{
                  calculator.fahrenheit.heatTransferCoefficient | decimal(2)
                }}</strong>
                Btu/(hr ft<sup>2</sup> .&deg;F)
              </td>
              <td v-if="isFahrenheit && !disableMpg">
                <strong>{{
                  calculator.mpgFahrenheit.heatTransferCoefficient | decimal(2)
                }}</strong>
                Btu/(hr ft<sup>2</sup> .&deg;F)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="columns mt-6 is-multiline is-variable is-8 charting"
        v-if="!disableMpg"
      >
        <div class="column is-6">
          <header class="content is-centered">
            <h5>Pressure drop</h5>
          </header>
          <apexchart
            v-if="isCelsius"
            type="bar"
            height="350"
            :options="graph.pressureDrop.celsius.options"
            :series="graph.pressureDrop.celsius.series"
          ></apexchart>
          <apexchart
            v-if="isFahrenheit"
            type="bar"
            height="350"
            :options="graph.pressureDrop.fahrenheit.options"
            :series="graph.pressureDrop.fahrenheit.series"
          ></apexchart>
        </div>
        <div class="column is-6">
          <header class="content is-centered">
            <h5>Heat Transfer Coefficient</h5>
          </header>
          <apexchart
            v-if="isCelsius"
            type="bar"
            height="350"
            :options="graph.heatTransferCoefficient.celsius.options"
            :series="graph.heatTransferCoefficient.celsius.series"
          ></apexchart>
          <apexchart
            v-if="isFahrenheit"
            type="bar"
            height="350"
            :options="graph.heatTransferCoefficient.fahrenheit.options"
            :series="graph.heatTransferCoefficient.fahrenheit.series"
          ></apexchart>
        </div>
      </div>
    </div>
    <footer class="buttons">
      <button class="button" @click="print">
        Print data
      </button>
    </footer>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import Calculator from '@/module/Calculator'
import TemperPropertiesChart from '@/module/ThermalPropertiesChart'

export default {
  name: 'ThermalProperties',
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      form: {
        temper: -10,
        mode: 'celsius',
        operatingTemperature: 0,
        length: 0,
        tubeDiameter: 0,
        fluidFlowVelocity: 0
      }
    }
  },
  filters: {
    decimal (value, count) {
      return value.toFixed(count)
    }
  },
  computed: {
    errors () {
      const products = [-10, -15, -20, -30, -40, -55, -60]

      const match = products.find(product => product === this.form.temper)

      let message = ''

      if (this.isCelsius) {
        const max = 40

        if (
          this.form.temper === match &&
          (this.form.operatingTemperature < match ||
            this.form.operatingTemperature > max)
        ) {
          message = `Value should be between ${match}&#176;C and ${max}&#176;C`
        }
      } else if (this.isFahrenheit) {
        const fahrenheit = Math.round((match * 9) / 5 + 32)

        const max = 104

        if (
          this.form.temper === match &&
          (this.form.operatingTemperature < fahrenheit ||
            this.form.operatingTemperature > max)
        ) {
          message = `Value should be between ${fahrenheit}&#176;F and ${max}&#176;F`
        }
      }

      return {
        operatingTemperature: message
      }
    },
    calculator () {
      return Calculator.get(this.form)
    },
    graph () {
      return TemperPropertiesChart.get(this.form, this.calculator)
    },
    isCelsius () {
      return this.form.mode === 'celsius'
    },
    isFahrenheit () {
      return this.form.mode === 'fahrenheit'
    },
    disableMpg () {
      return this.form.temper < -40
    }
  },
  methods: {
    print () {
      this.$htmlToPaper('thermal-properties-table')
    },
    unitToggle (celsius, fahrenheit) {
      return this.isCelsius ? celsius : fahrenheit
    }
  }
}
</script>
