import Celsius from '@/module/products/temper-55/calculation/Celsius'
import Fahrenheit from '@/module/products/temper-55/calculation/Fahrenheit'
import MpgCelsius from '@/module/products/temper-55/calculation/MpgCelsius'
import MpgFahrenheit from '@/module/products/temper-55/calculation/MpgFahrenheit'
import Expansion from '@/module/products/temper-55/calculation/Expansion'
import TemperBase from '@/module/TemperBase'

export default class TemperMinusFiftyFive extends TemperBase {
  calculators = {
    Celsius,
    Fahrenheit,
    MpgCelsius,
    MpgFahrenheit,
    Expansion
  }
}
