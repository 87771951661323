import CelsiusBase from '@/module/CelsiusBase'

export default class Celsius extends CelsiusBase {
  density () {
    return -0.2284 * this.attributes.celsius.operatingTemperature + 1118.9
  }

  specificHeatCapacity () {
    return 0.00000004 *
      Math.pow(this.attributes.celsius.operatingTemperature, 3) -
      0.00002 *
      Math.pow(this.attributes.celsius.operatingTemperature, 2) +
      0.0026 *
      this.attributes.celsius.operatingTemperature + 3.4029
  }

  thermalConductivity () {
    return 0.0014 * this.attributes.celsius.operatingTemperature + 0.4971
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        11.287 *
        Math.pow((Math.log((this.attributes.celsius.operatingTemperature + 273.15))), 2) -
        134.17 *
        Math.log((this.attributes.celsius.operatingTemperature + 273.15)) +
        398.31
      )
    )
  }
}
