import ExpansionBase from '@/module/ExpansionBase'

export default class Expansion extends ExpansionBase {
  minimumDensity () {
    return 1147 - (0.22142857 * this.attributes.celsius.minimumFluidTemperature) - (0.00142857 * Math.pow(this.attributes.celsius.minimumFluidTemperature, 2)) + (2.98156E-19 * Math.pow(this.attributes.celsius.minimumFluidTemperature, 3))
  }

  maximumDensity () {
    return 1147 - (0.22142857 * this.attributes.celsius.maximumFluidTemperature) - (0.00142857 * Math.pow(this.attributes.celsius.maximumFluidTemperature, 2)) + (2.98156E-19 * Math.pow(this.attributes.celsius.maximumFluidTemperature, 3))
  }
}
