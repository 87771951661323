export default class TemperPropertiesChart {
  form = {}
  calculator = {}

  constructor (form, calculator) {
    this.form = form
    this.calculator = calculator
  }

  static get (form, calculator) {
    return new TemperPropertiesChart(form, calculator).options()
  }

  properties () {
    let count = 0

    return {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
          tools: {
            download: false
          }
        }
      },
      fill: {
        colors: [
          function ({ value, seriesIndex, w }) {
            count++

            if (count % 2 !== 0) {
              return '#149BF3'
            }

            return '#C6D9F1'
          }
        ]
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [`Temper ${this.form.temper}`, `MPG ${this.form.temper}`]
      }
    }
  }

  options () {
    let options = this.properties()

    return {
      pressureDrop: {
        celsius: {
          series: [
            {
              data: [
                this.calculator.celsius.pressureDrop.toFixed(2),
                this.calculator.mpgCelsius.pressureDrop.toFixed(2)
              ]
            }
          ],
          options: options
        },
        fahrenheit: {
          series: [
            {
              data: [
                this.calculator.fahrenheit.pressureDrop.toFixed(2),
                this.calculator.mpgFahrenheit.pressureDrop.toFixed(2)
              ]
            }
          ],
          options: options
        }
      },
      heatTransferCoefficient: {
        celsius: {
          series: [
            {
              data: [
                this.calculator.celsius.heatTransferCoefficient.toFixed(2),
                this.calculator.mpgCelsius.heatTransferCoefficient.toFixed(2)
              ]
            }
          ],
          options: options
        },
        fahrenheit: {
          series: [
            {
              data: [
                this.calculator.fahrenheit.heatTransferCoefficient.toFixed(2),
                this.calculator.mpgFahrenheit.heatTransferCoefficient.toFixed(2)
              ]
            }
          ],
          options: options
        }
      }
    }
  }
}
