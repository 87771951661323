import MpgBase from '@/module/MpgBase'

export default class MpgCelsius extends MpgBase {
  density () {
    return 1058.9024 - 0.597561 * this.attributes.celsius.operatingTemperature
  }

  specificHeatCapacity () {
    return -0.0000001 *
      Math.pow(this.attributes.celsius.operatingTemperature, 3) +
      0.000005 *
      Math.pow(this.attributes.celsius.operatingTemperature, 2) +
      0.0043 *
      this.attributes.celsius.operatingTemperature +
      3.3908
  }

  thermalConductivity () {
    return 0.0015 * this.attributes.celsius.operatingTemperature + 0.3235
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        -78.058 +
        34.103 *
        Math.log(this.attributes.celsius.operatingTemperature + 273.15) -
        3.5424 *
        Math.pow((Math.log(this.attributes.celsius.operatingTemperature + 273.15)), 2)
      )
    )
  }
}
