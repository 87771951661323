import CelsiusBase from '@/module/CelsiusBase'

export default class Celsius extends CelsiusBase {
  density () {
    return -0.0012 * Math.pow(this.attributes.celsius.operatingTemperature, 2) - 0.3068 * this.attributes.celsius.operatingTemperature + 1184
  }

  specificHeatCapacity () {
    return 3.07504 +
      (0.00299 * this.attributes.celsius.operatingTemperature) -
      (0.0000270232 * Math.pow(this.attributes.celsius.operatingTemperature, 2))
  }

  thermalConductivity () {
    return 0.001256 * (273 + this.attributes.celsius.operatingTemperature) + 0.1175
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        252.73 -
        83.475 *
        Math.log(this.attributes.celsius.operatingTemperature + 273.15) +
        6.8808 *
        Math.pow((Math.log(this.attributes.celsius.operatingTemperature + 273.15)), 2)
      )
    )
  }
}
