import { temperProduct } from '@/helpers'
import TemperMinusTen from '@/module/products/temper-10/TemperMinusTen'
import TemperMinusFifteen from '@/module/products/temper-15/TemperMinusFifteen'
import TemperMinusTwenty from '@/module/products/temper-20/TemperMinusTwenty'
import TemperMinusThirty from '@/module/products/temper-30/TemperMinusThirty'
import TemperMinusForty from '@/module/products/temper-40/TemperMinusForty'
import TemperMinusFiftyFive from '@/module/products/temper-55/TemperMinusFiftyFive'
import TemperMinusSixty from '@/module/products/temper-60/TemperMinusSixty'

const products = {
  TemperMinusTen,
  TemperMinusFifteen,
  TemperMinusTwenty,
  TemperMinusThirty,
  TemperMinusForty,
  TemperMinusFiftyFive,
  TemperMinusSixty
}

export default class Calculator {
  attributes = {
    temper: 0,
    mode: 'celsius',
    celsius: {
      operatingTemperature: 0,
      length: 0,
      tubeDiameter: 0,
      fluidFlowVelocity: 0
    },
    fahrenheit: {
      operatingTemperature: 0,
      length: 0,
      tubeDiameter: 0,
      fluidFlowVelocity: 0
    }
  }

  constructor (attributes) {
    Object.assign(this.attributes, {
      temper: attributes.temper,
      mode: attributes.mode
    })

    if (attributes.mode === 'celsius') {
      Object.assign(this.attributes, {
        celsius: {
          operatingTemperature: attributes.operatingTemperature,
          length: attributes.length,
          tubeDiameter: attributes.tubeDiameter,
          fluidFlowVelocity: attributes.fluidFlowVelocity,
          volume: attributes.volume,
          minimumFluidTemperature: attributes.minimumFluidTemperature,
          maximumFluidTemperature: attributes.maximumFluidTemperature
        },
        fahrenheit: {
          operatingTemperature: (attributes.operatingTemperature * 9 / 5) + 32,
          length: attributes.length * 3.281,
          tubeDiameter: attributes.tubeDiameter * 39.37,
          fluidFlowVelocity: attributes.fluidFlowVelocity * 3.281,
          volume: attributes.volume / 3.785411784,
          minimumFluidTemperature: (attributes.minimumFluidTemperature * 9 / 5) + 32,
          maximumFluidTemperature: (attributes.maximumFluidTemperature * 9 / 5) + 32
        }
      })
    }

    if (attributes.mode === 'fahrenheit') {
      Object.assign(this.attributes, {
        celsius: {
          operatingTemperature: (attributes.operatingTemperature - 32) * 5 / 9,
          length: attributes.length / 3.281,
          tubeDiameter: attributes.tubeDiameter / 39.37,
          fluidFlowVelocity: attributes.fluidFlowVelocity / 3.281,
          volume: attributes.volume * 3.785411784,
          minimumFluidTemperature: (attributes.minimumFluidTemperature - 32) * 5 / 9,
          maximumFluidTemperature: (attributes.maximumFluidTemperature - 32) * 5 / 9
        },
        fahrenheit: {
          operatingTemperature: attributes.operatingTemperature,
          length: attributes.length,
          tubeDiameter: attributes.tubeDiameter,
          fluidFlowVelocity: attributes.fluidFlowVelocity,
          volume: attributes.volume,
          minimumFluidTemperature: attributes.minimumFluidTemperature,
          maximumFluidTemperature: attributes.maximumFluidTemperature
        }
      })
    }
  }

  static get (attributes) {
    return new Calculator(attributes).handle()
  }

  handle () {
    const name = temperProduct(this.attributes.temper)

    if (!products[name]) {
      throw Error(`Product ${name} does not exist. If it does, make sure it is referenced in Calculator.js`)
    }

    return products[name].calculate(this.attributes)
  }
}
