import 'smoothscroll-for-websites'
import Vue from 'vue'
import VueHelpers from '@wyxos/vue-helpers'
import { ConfigProgrammatic, Snackbar, Loading, Button, Carousel, Tabs } from 'buefy'
import Navigation from './plugins/navigation'
import CookieNotification from './plugins/cookie-notification'
import Header from './plugins/header'
import Faq from './plugins/faq'
import VuePreventParentScroll from 'vue-prevent-parent-scroll'
import VueScrollReveal from 'vue-scroll-reveal'
// import x5GMaps from 'x5-gmaps'
import VueHtmlToPaper from 'vue-html-to-paper'

import magnificPopup from 'magnific-popup'

window.$ = window.jQuery = require('jquery')

/** Buefy Configurations and components */
ConfigProgrammatic.setOptions({
  defaultIconPack: 'fas'
})
Vue.use(Snackbar)
Vue.use(Loading)
Vue.use(Button)
Vue.use(Carousel)
Vue.use(Tabs)

Vue.use(VueHelpers)
Vue.use(Navigation)
Vue.use(Header)
Vue.use(Faq)
Vue.use(CookieNotification)
Vue.use(VuePreventParentScroll)
Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal',
  duration: 600,
  distance: '80px',
  reset: false,
  mobile: true
})

const options = {
  name: 'thermal_properties',
  specs: [
    'fullscreen=no',
    'titlebar=yes',
    'scrollbars=no'
  ],
  styles: [
    'https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.css',
    'https://temper.orckidlab.com/app/themes/sage/dist/css/landscape.css'
  ]
}

Vue.use(VueHtmlToPaper, options)

// Vue.use(x5GMaps, 'AIzaSyAqp4trLMcnGrxxtyRpm54dI8XH9A4waIo')

const files = require.context('./components', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/* eslint-disable no-unused-vars */
const app = new Vue({
  el: '#app',
  data: {
    isFullPage: true,
    isReady: false
  },
  mounted () {
    $('.popup-with-zoom-anim').magnificPopup({
      type: 'inline',

      fixedContentPos: false,
      fixedBgPos: true,

      overflowY: 'auto',

      closeBtnInside: true,
      preloader: false,

      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in'
    })
  }
})
