import MpgBase from '@/module/MpgBase'

export default class MpgCelsius extends MpgBase {
  density () {
    return 1030.62 - 0.31019 * this.attributes.celsius.operatingTemperature
  }

  specificHeatCapacity () {
    return 0.0000004 * Math.pow(this.attributes.celsius.operatingTemperature, 3) - 0.00002 * Math.pow(this.attributes.celsius.operatingTemperature, 2) + 0.0026 * this.attributes.celsius.operatingTemperature + 3.8784
  }

  thermalConductivity () {
    return 0.0024 * this.attributes.celsius.operatingTemperature + 0.3986
  }

  kinematicViscosity () {
    return Math.exp(Math.sinh(-144.55 + 58.425 * Math.log(this.attributes.celsius.operatingTemperature + 273.15) - 5.7796 * Math.pow((Math.log(this.attributes.celsius.operatingTemperature + 273.15)), 2)))
  }
}
