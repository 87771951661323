export default {
  install (Vue) {
    Vue.prototype.$navigation = new Vue({
      data () {
        return {
          active: false
        }
      },
      methods: {
        toggleNavigation () {
          this.active = !this.active
        }
      }
    })
  }
}
