export default class CalculationBase {
  attributes = {}

  constructor (attributes) {
    if (new.target === CalculationBase) {
      throw new TypeError('Cannot construct CalculationBase instances directly')
    }

    Object.assign(this.attributes, attributes)
  }

  static get (...attributes) {
    return new this(...attributes).calculate()
  }

  calculate () {
    [
      'density',
      'specificHeatCapacity',
      'thermalConductivity',
      'dynamicViscosity',
      'kinematicViscosity',
      'reynoldsNumber',
      'pressureDrop',
      'heatTransferCoefficient'
    ].forEach(method => {
      if (!(method in this)) {
        throw Error(`The method ${method} does not exist in ${this.constructor.name}.`)
      }
    })

    return {
      density: this.density(),
      specificHeatCapacity: this.specificHeatCapacity(),
      thermalConductivity: this.thermalConductivity(),
      dynamicViscosity: this.dynamicViscosity(),
      kinematicViscosity: this.kinematicViscosity(),
      reynoldsNumber: this.reynoldsNumber(),
      pressureDrop: this.pressureDrop(),
      heatTransferCoefficient: this.heatTransferCoefficient()
    }
  }
}
