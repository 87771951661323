export default class TemperBase {
  attributes = {}

  calculators = {}

  constructor (attributes) {
    Object.assign(this.attributes, attributes)
  }

  static calculate (attributes) {
    return new this(attributes).get()
  }

  get () {
    const celsius = this.calculators.Celsius.get(this.attributes)

    const fahrenheit = this.calculators.Fahrenheit.get(this.attributes, celsius)

    const mpgCelsius = this.calculators.MpgCelsius.get(this.attributes)

    const mpgFahrenheit = this.calculators.MpgFahrenheit.get(this.attributes, mpgCelsius)

    const expansion = this.calculators.Expansion.get(this.attributes)

    return {
      attributes: this.attributes,
      celsius,
      fahrenheit,
      mpgCelsius,
      mpgFahrenheit,
      expansion
    }
  }
}
