import CalculationBase from '@/module/CalculationBase'

export default class MpgBase extends CalculationBase {
  dynamicViscosity () {
    return this.kinematicViscosity() * this.density() / 1000
  }

  reynoldsNumber () {
    return (this.attributes.celsius.tubeDiameter * this.attributes.celsius.fluidFlowVelocity / this.kinematicViscosity()) * Math.pow(10, 6)
  }

  pressureDrop () {
    if (!this.attributes.celsius.fluidFlowVelocity || !this.attributes.celsius.tubeDiameter) {
      return 0
    }

    return (
      this.reynoldsNumber() > 2300
        ? (
          0.092 *
          this.density() *
          Math.pow((this.kinematicViscosity() / 1000000), 0.2) *
          Math.pow(this.attributes.celsius.fluidFlowVelocity, 1.8) *
          this.attributes.celsius.length / Math.pow(this.attributes.celsius.tubeDiameter, 1.2)
        )
        : (
          32 *
          (this.dynamicViscosity() / 1000) *
          this.attributes.celsius.fluidFlowVelocity *
          this.attributes.celsius.length / Math.pow(this.attributes.celsius.tubeDiameter, 2)
        )
    ) / 1000
  }

  heatTransferCoefficient () {
    if (!this.attributes.celsius.fluidFlowVelocity || !this.attributes.celsius.tubeDiameter) {
      return 0
    }

    return (
      this.reynoldsNumber() > 2300
        ? (
          0.023 *
          Math.pow(this.thermalConductivity(), (2 / 3)) *
          Math.pow((this.density() * this.specificHeatCapacity()), (1 / 3)) *
          Math.pow((this.kinematicViscosity() / 1000000), (1 / 3 - 0.8)) *
          Math.pow(this.attributes.celsius.fluidFlowVelocity, (0.8)) / Math.pow(this.attributes.celsius.tubeDiameter, 0.2)
        )
        : (
          1.86 *
          Math.pow(this.thermalConductivity(), (2 / 3)) *
          Math.pow((this.density() * this.specificHeatCapacity()), (1 / 3)) *
          Math.pow((this.attributes.celsius.fluidFlowVelocity / (this.attributes.celsius.tubeDiameter * this.attributes.celsius.length)), (1 / 3))
        )
    ) * 10
  }
}
