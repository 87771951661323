import CalculationBase from '@/module/CalculationBase'

export default class ConversionBase extends CalculationBase {
  previousCalc = {}

  constructor (props, previousCalc) {
    super(props)

    Object.assign(this.previousCalc, previousCalc)
  }

  density () {
    return this.previousCalc.density * 0.0624279606
  }

  specificHeatCapacity () {
    return this.previousCalc.specificHeatCapacity * 0.238845863
  }

  thermalConductivity () {
    return this.previousCalc.thermalConductivity * 0.5781759824
  }

  dynamicViscosity () {
    return this.previousCalc.dynamicViscosity
  }

  kinematicViscosity () {
    return this.previousCalc.kinematicViscosity
  }

  reynoldsNumber () {
    return this.previousCalc.reynoldsNumber
  }

  pressureDrop () {
    return this.previousCalc.pressureDrop * 0.145037743897283
  }

  heatTransferCoefficient () {
    return this.previousCalc.heatTransferCoefficient * 0.1761101837
  }
}
