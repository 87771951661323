import MpgBase from '@/module/MpgBase'

export default class MpgCelsius extends MpgBase {
  density () {
    return -0.422857 * this.attributes.celsius.operatingTemperature + 1036.7857
  }

  specificHeatCapacity () {
    return -0.0000006 *
      Math.pow(this.attributes.celsius.operatingTemperature, 3) +
      0.00003 *
      Math.pow(this.attributes.celsius.operatingTemperature, 2) +
      0.0032 *
      this.attributes.celsius.operatingTemperature +
      3.7788
  }

  thermalConductivity () {
    return 0.0023 * this.attributes.celsius.operatingTemperature + 0.3797
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        -162.82 + 64.608 *
        Math.log(this.attributes.celsius.operatingTemperature + 273.15) -
        6.2976 *
        Math.pow(
          (Math.log(this.attributes.celsius.operatingTemperature + 273.15)
          ), 2)
      )
    )
  }
}
