<template>
  <article>
      <div class="column is-half">
        <a :href="permalink" :title="title">
          <div class="card-image">
            <figure class="image">
              <img v-if="image" :src="image" :alt="title"/>
              <figcaption>
                <h4>{{ title }}</h4>
              </figcaption>
            </figure>
          </div>
          <div class="card-content">
            <div class="content">
              <div v-if="categories" v-for="categoryList in categories">
                <template v-for="category in categoryList">
                  <span>{{ category['name'] }}</span>
                </template>
              </div>
            </div>
          </div>
        </a>
      </div>
  </article>
</template>
<script>
export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    id () {
      return `post${this.post.id}`
    },
    title () {
      return this.post.title.rendered
    },
    permalink () {
      return this.post.link
    },
    image () {
      return this.post.image
    },
    categories () {
      return this.post._embedded['wp:term']
    }
  },
  mounted () {
  },
  methods: {},
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    }
  }
}
</script>
