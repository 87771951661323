<template>
  <gmaps-map :options="mapOptions">
    <gmaps-marker :zoom="1" :position="{ lat: lat, lng: lng }"/>
  </gmaps-map>
</template>

<script>
import { gmapsMap, gmapsMarker } from 'x5-gmaps'

export default {
  props: {
    lat: '',
    lng: ''
  },
  data: () => ({
    mapOptions: {
      center: { lat: 0, lng: 0 },
      zoom: 12
    }
  }),
  mounted () {
    this.mapOptions = {
      center: { lat: this.lat, lng: this.lng },
      zoom: 12
    }
  },
  components: { gmapsMap, gmapsMarker }
}
</script>
