import MpgBase from '@/module/MpgBase'

export default class MpgCelsius extends MpgBase {
  density () {
    return 1062.0661 - 0.626033 * this.attributes.celsius.operatingTemperature
  }

  specificHeatCapacity () {
    return -0.0000001 *
      Math.pow(this.attributes.celsius.operatingTemperature, 3) -
      0.000001 *
      Math.pow(this.attributes.celsius.operatingTemperature, 2) +
      0.0043 *
      this.attributes.celsius.operatingTemperature +
      3.2963
  }

  thermalConductivity () {
    return 0.0014 * this.attributes.celsius.operatingTemperature + 0.3069
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        -69.984 +
        31.104 *
        Math.log(this.attributes.celsius.operatingTemperature + 273.15) -
        3.2623 *
        Math.pow((Math.log(this.attributes.celsius.operatingTemperature + 273.15)), 2)
      )
    )
  }
}
