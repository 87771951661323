<template>
  <div>
<!--    <ul class="classic-tabs">
      <li @click="calculator = 'ThermalProperties'" :class="{active: calculator === 'ThermalProperties'}">Thermal
        properties
      </li>
      <li @click="calculator = 'ThermalExpansion'" :class="{active: calculator === 'ThermalExpansion'}">Thermal
        expansion
      </li>
    </ul>-->
<!--    <component :is="calculator"></component>-->
    <thermal-properties></thermal-properties>
    <thermal-expansion></thermal-expansion>
  </div>
</template>

<script>
import ThermalProperties from '@components/ThermalProperties'
import ThermalExpansion from '@components/ThermalExpansion'

export default {
  name: 'Calculator',
  components: {
    ThermalProperties,
    ThermalExpansion
  },
  data () {
    return {
      calculator: 'ThermalProperties'
    }
  }
}
</script>
