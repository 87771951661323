export default class ExpansionBase {
  attributes = {}

  constructor (attributes) {
    Object.assign(this.attributes, attributes)
  }

  calculate () {
    const maximumDensity = this.maximumDensity()
    const minimumDensity = this.minimumDensity()
    const percentage = (minimumDensity - maximumDensity) / maximumDensity * 100
    return {
      minimumDensity: minimumDensity,
      maximumDensity: maximumDensity,
      percentage: percentage,
      celsius: {
        volume: percentage / 100 * this.attributes.celsius.volume
      },
      fahrenheit: {
        volume: percentage / 100 * this.attributes.fahrenheit.volume
      }
    }
  }

  static get (attributes) {
    return new this(attributes).calculate()
  }
}
