import ExpansionBase from '@/module/ExpansionBase'

export default class Expansion extends ExpansionBase {
  minimumDensity () {
    return -0.2284 * this.attributes.celsius.minimumFluidTemperature + 1118.9
  }

  maximumDensity () {
    return -0.2284 * this.attributes.celsius.maximumFluidTemperature + 1118.9
  }
}
