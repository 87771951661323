import CelsiusBase from '@/module/CelsiusBase'

export default class Celsius extends CelsiusBase {
  density () {
    return 1090 -
      (0.2 * this.attributes.celsius.operatingTemperature) +
      ((1.66533 * Math.pow(10, -16)) * Math.pow(this.attributes.celsius.operatingTemperature, 2)) -
      ((1.89735 * Math.pow(10, -18)) * Math.pow(this.attributes.celsius.operatingTemperature, 3))
  }

  specificHeatCapacity () {
    return 3.54183 +
      (0.00201 * this.attributes.celsius.operatingTemperature) -
      (0.0000132589 * Math.pow(this.attributes.celsius.operatingTemperature, 2))
  }

  thermalConductivity () {
    return 0.001483 *
      (273 + this.attributes.celsius.operatingTemperature) +
      0.1094
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        13.342 *
        Math.pow((
          Math.log(
            (this.attributes.celsius.operatingTemperature + 273.15)
          )
        ), 2) -
        158.24 *
        Math.log(
          (this.attributes.celsius.operatingTemperature + 273.15)
        ) +
        468.67
      )
    )
  }
}
