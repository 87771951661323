import MpgBase from '@/module/MpgBase'

export default class MpgCelsius extends MpgBase {
  density () {
    return 1048.3175 - 0.51746 * this.attributes.celsius.operatingTemperature
  }

  specificHeatCapacity () {
    return 0.000002 * Math.pow(this.attributes.celsius.operatingTemperature, 2) + 0.0037 * this.attributes.celsius.operatingTemperature + 3.6057
  }

  thermalConductivity () {
    return 0.0018 * this.attributes.celsius.operatingTemperature + 0.3516
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        -132.49 +
        53.802 *
        Math.log(this.attributes.celsius.operatingTemperature + 273.15) -
        5.3292 *
        Math.pow((Math.log(this.attributes.celsius.operatingTemperature + 273.15)), 2)
      )
    )
  }
}
