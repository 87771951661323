export default {
  install (Vue) {
    Vue.prototype.$header = new Vue({
      data () {
        return {
          position: 0
        }
      },
      methods: {
        setMargin () {
          const header = document.querySelector('#main-header')
          const content = document.querySelector('#main-content')
          let spacing = header.clientHeight
          content.style.marginTop = `${spacing}px`
        },
        debounce (func, wait = 10, immediate = true) {
          let timeout
          return function () {
            const context = this
            const args = arguments
            const later = function () {
              timeout = null
              if (!immediate) func.apply(context, args)
            }
            const callNow = immediate && !timeout
            clearTimeout(timeout)
            timeout = setTimeout(later, wait)
            if (callNow) func.apply(context, args)
          }
        },
        checkPosition () {
          const header = document.querySelector('#main-header')
          // const hero = document.querySelector('.hero')
          const windowY = window.scrollY
          const visible = 'visible'
          const hidden = 'hidden'
          // const heroScroll = 'scrolling'
          if (windowY < this.position) {
            // console.log('Going up')
            header.classList.add(visible)
            header.classList.remove(hidden)
            // hero.classList.remove(heroScroll)
          } else {
            // console.log('Going down')
            header.classList.add(hidden)
            header.classList.remove(visible)
            // hero.classList.add(heroScroll)
          }
          this.position = windowY
        }
      },
      created () {
        this.setMargin()
        window.addEventListener('resize', this.setMargin)
        // window.addEventListener('scroll', this.debounce(this.checkPosition))
      },
      destroyed () {
        // window.removeEventListener('resize', this.setMargin)
        // window.removeEventListener('scroll', this.debounce(this.checkPosition))
      }
    })
  }
}
