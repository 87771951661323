import CelsiusBase from '@/module/CelsiusBase'

export default class Celsius extends CelsiusBase {
  density () {
    return -0.0013 * Math.pow(this.attributes.celsius.operatingTemperature, 2) - 0.361 * this.attributes.celsius.operatingTemperature + 1214.9
  }

  specificHeatCapacity () {
    return 0.0000001 *
      Math.pow(this.attributes.celsius.operatingTemperature, 3) -
      0.00003 *
      Math.pow(this.attributes.celsius.operatingTemperature, 2) +
      0.0023 *
      this.attributes.celsius.operatingTemperature +
      2.9767
  }

  thermalConductivity () {
    return 0.001099 * (273 + this.attributes.celsius.operatingTemperature) + 0.1433
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        122.28 -
        37.639 *
        Math.log(this.attributes.celsius.operatingTemperature + 273.15) +
        2.8599 *
        Math.pow((Math.log(this.attributes.celsius.operatingTemperature + 273.15)), 2)
      )
    )
  }
}
