import ExpansionBase from '@/module/ExpansionBase'

export default class Expansion extends ExpansionBase {
  minimumDensity () {
    return 0.00002 * Math.pow(this.attributes.celsius.minimumFluidTemperature, 3) - 0.0012 * Math.pow(this.attributes.celsius.minimumFluidTemperature, 2) - 0.4703 * this.attributes.celsius.minimumFluidTemperature + 1249.8
  }

  maximumDensity () {
    return 0.00002 * Math.pow(this.attributes.celsius.maximumFluidTemperature, 3) - 0.0012 * Math.pow(this.attributes.celsius.maximumFluidTemperature, 2) - 0.4703 * this.attributes.celsius.maximumFluidTemperature + 1249.8
  }
}
