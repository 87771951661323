import CelsiusBase from '@/module/CelsiusBase'

export default class Celsius extends CelsiusBase {
  density () {
    return 1147 -
      (0.22142857 * this.attributes.celsius.operatingTemperature) -
      (0.00142857 * Math.pow(this.attributes.celsius.operatingTemperature, 2)) +
      (2.98156E-19 * Math.pow(this.attributes.celsius.operatingTemperature, 3))
  }

  specificHeatCapacity () {
    return 3.26252 + (0.00286 * this.attributes.celsius.operatingTemperature) - (0.0000122673 * Math.pow(this.attributes.celsius.operatingTemperature, 2))
  }

  thermalConductivity () {
    return 0.001342 * (273 + this.attributes.celsius.operatingTemperature) + 0.1144
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        354.77 -
        119 *
        Math.log(this.attributes.celsius.operatingTemperature + 273.15) +
        9.968 *
        Math.pow((Math.log(this.attributes.celsius.operatingTemperature + 273.15)), 2)
      )
    )
  }
}
