import CelsiusBase from '@/module/CelsiusBase'

export default class Celsius extends CelsiusBase {
  density () {
    return 0.00002 *
      Math.pow(this.attributes.celsius.operatingTemperature, 3) -
      0.0012 *
      Math.pow(this.attributes.celsius.operatingTemperature, 2) -
      0.475 *
      this.attributes.celsius.operatingTemperature +
      1269.8
  }

  specificHeatCapacity () {
    return (
      -0.00000001332411 *
      Math.pow((this.attributes.celsius.operatingTemperature + 273.15), 4) +
      0.00001409489 *
      Math.pow((this.attributes.celsius.operatingTemperature + 273.15), 3) -
      0.005560331 *
      Math.pow((this.attributes.celsius.operatingTemperature + 273.15), 2) +
      0.9726031 *
      (this.attributes.celsius.operatingTemperature + 273.15) -
      61.14406)
  }

  thermalConductivity () {
    return 0.0008 * this.attributes.celsius.operatingTemperature + 0.4241
  }

  kinematicViscosity () {
    return Math.exp(
      Math.sinh(
        2.5 +
        3.945 *
        Math.log(this.attributes.celsius.operatingTemperature + 273.15) -
        0.74 *
        Math.pow((Math.log(this.attributes.celsius.operatingTemperature + 273.15)), 2)
      )
    )
  }
}
