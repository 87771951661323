import ExpansionBase from '@/module/ExpansionBase'

export default class Expansion extends ExpansionBase {
  minimumDensity () {
    return 1090 -
      (0.2 * this.attributes.celsius.minimumFluidTemperature) +
      (1.66533E-16 * Math.pow(this.attributes.celsius.minimumFluidTemperature, 2)) -
      (
        (1.89735E-18) * Math.pow(this.attributes.celsius.minimumFluidTemperature, 3)
      )
  }

  maximumDensity () {
    return 1090 -
      (0.2 * this.attributes.celsius.maximumFluidTemperature) +
      (1.66533E-16 * Math.pow(this.attributes.celsius.maximumFluidTemperature, 2)) -
      ((1.89735E-18) * Math.pow(this.attributes.celsius.maximumFluidTemperature, 3))
  }
}
