<template>
  <div>
    <div class="columns" v-if="postData.posts">
      <slot v-for="item in postData.posts">
        <portfolios
          :key="item.id"
          :post="item"
        >
        </portfolios>
      </slot>
    </div>
    <footer class="buttons is-centered" v-if="postData.show">
      <b-button :loading="isLoading" type="is-success" size="is-medium" outlined
                @click.prevent="fetchPosts(query.page)">Load more ({{ query.page }} of {{ postData.totalPages }})
      </b-button>
    </footer>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
      default: 'posts'
    },
    filter: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      postData: {
        apiURL: `/wp-json/wp/v2/${this.type}`,
        posts: [],
        totalPages: 0,
        show: true
      },
      query: {
        page: 0,
        per_page: 6,
        _embed: true
      }
    }
  },
  mounted () {
    this.fetchPosts()
  },
  methods: {
    fetchPosts () {
      this.query.page++
      return this.$axios().get(this.postData.apiURL, {
        params: Object.assign({}, this.query, this.filter)
      })
        .then(response => {
          this.postData.totalPages = Number(response.headers['x-wp-totalpages'])
          this.postData.show = this.query.page !== this.postData.totalPages
          response.data.forEach(element => {
            this.postData.posts.push(element)
          })
        })
        .catch(() => {
          this.query.page--
        })
    }
  }
}
</script>
